@font-face {
  font-family: "Atlas Grotesk";
  src: url("./fonts/AtlasGrotesk-Light.otf");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Atlas Grotesk";
  src: url("./fonts/AtlasGrotesk-Regular.otf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Atlas Grotesk";
  src: url("./fonts/AtlasGrotesk-Medium.otf");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Atlas Grotesk";
  src: url("./fonts/AtlasGrotesk-Bold.otf");
  font-weight: 600;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Atlas Grotesk', sans-serif;
  background-color: #FFF;
  font-weight: 100;
  color: #000000;
  font-size: 1.1rem;
  line-height: 1.5rem;
}

.noscroll {
  overflow: hidden;
  position: relative;
  height: 100%;
}
